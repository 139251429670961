<template>
  <div>
    <validation-observer ref="createInstallation">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-card>
          <!-- rest of form -->
          <b-row>
            <b-col cols="12">
              <h4>
                {{ $t("AñadirInstallations") }}
              </h4>
            </b-col>
            <!-- divider -->
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
          <!-- details installation -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.collapse-details>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("DetallesInstallation") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="collapse-details" visible class="mx-1 mb-2">
                <b-row>
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('FacilityName')"
                      rules="required"
                      collapse-id="collapse-details"
                    >
                      <b-form-group
                        collapse-id="collapse-details"
                        :label="$t('FacilityName')"
                        label-for="installation-name"
                      >
                        <b-form-input
                          collapse-id="collapse-details"
                          v-model="name"
                          name="name"
                          :placeholder="$t('FacilityName')"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col sm="6" >
                    <b-form-group
                      :label="$t('FacilityPower')"
                      label-for="facility_power"
                    >
                      <b-form-input
                        v-model="power"
                        name="name"
                        type="number"
                        :placeholder="$t('FacilityPower')"
                      />
                    </b-form-group>
                  </b-col> -->
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Tipo')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Tipo')"
                        label-for="installation-product"
                      >
                        <SelectProducts v-model="product" type="installation" />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('FechaConstruccion')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('FechaConstruccion')"
                        label-for="installation-purchase_date"
                      >
                        <flat-pickr
                          v-model="facility_date"
                          name="purchase_date"
                          class="form-control"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Address')"
                      rules=""
                    >
                      <b-form-group :label="$t('Address')" label-for="address">
                        <b-input-group>
                          <b-form-input
                            v-model="address"
                            name="address"
                            :placeholder="$t('Address')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" class="mb-2" >
                    <p>{{ $t('TextClickMap') }}</p>
                    <l-map ref="map" :zoom="zoom" :center="center" @click="addMarker" >
                      <l-tile-layer :url="url" />
                      <l-marker
                          v-if="marker"
                          :lat-lng="marker"
                      >
                      </l-marker>
                    </l-map>
                  </b-col>
                  <!-- <b-col sm="6">
                    <b-form-group :label="$t('Province')" label-for="province">
                      <BaseSelect
                        :resource="'/provinces'"
                        @input="
                          [
                            (province = $event),
                            (town = ''),
                            (townsKey = new Date()),
                          ]
                        "
                        :value="province"
                        :label="'Provincia'"
                        :clearable="!town"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group :label="$t('Towns')" label-for="town">
                      <BaseSelect
                        :resource="'/towns'"
                        @input="town = $event"
                        :value="town"
                        :httpBody="{ province_id: province.id }"
                        :label="'Municipio'"
                        :readonly="!province"
                        :key="`${townsKey}`"
                        :clearable="true"
                      />
                    </b-form-group>
                  </b-col> -->
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('FacilityImages')"
                      label-for="facility-images"
                    >
                      <ImageDropzone
                        ref="documents"
                        :files="documents"
                        maxFiles="10"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- client -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.collapse-users>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("ClientInformation") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <!-- collapse -->
              <b-collapse id="collapse-users" class="mx-1 mb-2">
                <b-row class="pt-1">
                  <!-- subclient -->
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('Cliente')"
                      label-for="client-name"
                    >
                      <SubClientSelect
                        id="selectSubClient"
                        :value="selectedSubClient"
                        @input="addSubClient"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- contact Person -->
                  <template v-if="selectedSubClient">
                    <b-col sm="6">
                      <b-form-group
                        label-for="selectedCLient"
                        :label="$t('ContactPersons')"
                      >
                        <ContactPersonSelect
                          id="selectedCLient"
                          :subclient="selectedSubClient.id"
                          :value="selectedCLient"
                          @input="addContact"
                          :key="selectedSubClient.id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <template v-if="selectedCLient.length">
                        <EditableContactPersonList
                          :items="selectedCLient"
                          @delete="handleContactDelete"
                        />
                      </template>
                    </b-col>
                  </template>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- components-->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.collapse-components>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("assets") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="collapse-components" class="mx-1 mb-2">
                <b-row>
                  <b-col md="6" sm="12">
                    <AssetsSelect v-model="assets" />
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- manufacturer documents -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.manufacturer-documents>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("InterestingDocs") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="manufacturer-documents" class="mx-1 mb-2">
                <b-row>
                  <!-- <b-col sm="12">
                    <b-form-group :label="$t('ProductionAccessLinkInfo')" label-for="production-access">
                    <validation-provider #default="{ errors }" rules="url">
                      <b-form-input 
                        id="production-access" 
                        v-model="production_access"
                        name="production-access" 
                        :placeholder="$t('ProductionAccessLink')" 
                      />
                      <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  </b-col> -->
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('Documentos')"
                      label-for="info_docs"
                    >
                      <ImageDropzone
                        ref="info_docs"
                        :files="info_docs"
                        maxFiles="10"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- cover -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.cover-info>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("Cover") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="cover-info">
                <b-col sm="12" class="d-flex">
                  <b-form-group :label="$t('CoverType')" label-for="cover-type">
                    <b-form-radio-group
                      v-model="cover_type"
                      :options="optionsCoverType"
                      name="cover-type"
                      class="demo-inline-spacing"
                    />
                    <b-input-group
                      v-if="cover_type === 'tilt'"
                      class="mt-2 d-flex"
                    >
                      <b-form-input
                        v-model="angle"
                        name="angle"
                        type="number"
                        :placeholder="$t('Angle')"
                      />
                      <b-input-group-append is-text>
                        <b-img
                          fluid
                          :src="AngleIcon"
                          alt="AngleIcon"
                          style="height: 25px; width: 25px"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('CoverObservations')"
                    label-for="cover-observations"
                  >
                    <quill-editor
                      v-model="observations"
                      :options="editorOption"
                    />
                  </b-form-group>
                </b-col>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- security -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.security-info>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("Security") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="security-info">
                <b-row>
                  <b-col sm="6" v-if="currentCompany.id === 3">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Coste')"
                      rules=""
                    >
                      <b-form-group
                        :label="$t('Coste')"
                        label-for="equipos-coste"
                      >
                        <b-input-group append="€">
                          <b-form-input
                            v-model="coste"
                            name="equipos-coste"
                            type="number"
                            :placeholder="$t('Coste')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('Línea de vida')"
                      label-for="linea_vida"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="linea_vida"
                        v-model="linea_vida"
                        name="check-button-linea_vida"
                        switch
                        inline
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('Barandillas de seguridad')"
                      label-for="barandillas_seguridad"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="barandillas_seguridad"
                        v-model="barandillas_seguridad"
                        name="check-button-barandillas_seguridad"
                        switch
                        inline
                      />
                    </b-form-group>
  
                    <b-form-group
                      :label="$t('Redes de contanción')"
                      label-for="redes_contencion"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="redes_contencion"
                        v-model="redes_contencion"
                        name="check-button-redes_contencion"
                        switch
                        inline
                      />
                    </b-form-group>
  
                    <b-form-group
                      :label="$t('Zona de trabajo delimitada')"
                      label-for="zona_delimitada"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="zona_delimitada"
                        v-model="zona_delimitada"
                        name="check-button-zona_delimitada"
                        switch
                        inline
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('SecurityElements')"
                      label-for="security-elements"
                    >
                      <quill-editor
                        v-model="security_elements"
                        :options="editorOption"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- submit button -->
          <b-row>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ $t("Guardar") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BFormCheckbox } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp } from "@/libs/helpers";
import vSelect from "vue-select";
import moment from "moment";
import SelectProducts from "@/components/selectProducts/SelectProducts.vue";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet"
import {Icon, latLng} from "leaflet"
import "leaflet/dist/leaflet.css"
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
//new
import SubClientSelect from "@/components/subClients/select/SubClientSelect.vue";
import ContactPersonSelect from "@/components/contactPerson/select/ContactPersonSelect.vue";
import EditableContactPersonList from "@/components/contactPerson/list/EditableContactPersonList.vue";
import AssetsSelect from '@/components/assets/select/AssetsSelect.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    SelectProducts,
    ImageDropzone,
    BaseSelect,
    BFormCheckbox,
    AssetsSelect,
    SubClientSelect,
    ContactPersonSelect,
    EditableContactPersonList,
    LMap,
    LTileLayer,
    LMarker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: latLng(40.2085, -3.713),
      marker: '',
      latitude: '',
      longitude: '',

      townsKey: new Date(),
      AngleIcon: require("@/assets/images/icons/angle.svg"),
      currentPage: 1,
      pageLength: 10,
      subclient_id: this.$route.params.subclient_id || null,

      selectedCLient: [],
      selectedSubClient: null,

      client_contact_persons: [],
      contactPerson: {
        name: "",
        email: "",
        phone: "",
      },
      name: "",
      client_name: "",
      client_phone: "",
      production_access: "",
      power: "",
      cover_type: "",
      observations: "",
      linea_vida: false,
      barandillas_seguridad: false,
      redes_contencion: false,
      zona_delimitada: false,
      security_elements: "",
      angle: "",
      address: "",

      facility_date: moment(new Date()).format("DD/MM/YYYY"),
      pedido_num_id: "",
      documents: [],
      info_docs: [],
      last_review: "",
      frecuencia_revisiones: 12,
      next_review: "",
      product: "",
      user: "",
      status: "",
      num_equipos: 1,
      prox_revision_required: "",
      assets: [],
      optionsCoverType: [
        { text: this.$t("Flat"), value: "flat" },
        { text: this.$t("Tilt"), value: "tilt" },
      ],

      required,
      email,
      isDisabled: false,
      search: "",
      coste: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      totalItems: "products/getTotalItems",
      localeDatePicker: "languages/getLocaleDatePicker",
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      selectDepartments: "departments/getSelectDepartments",

      subclient: "subclients/getSubClient",
    }),
    collapseIcon() {
      return this.isCollapseUsers ? "ChevronUpIcon" : "ChevronDownIcon";
    },
    collapseIconComp() {
      return this.isCollapseComp ? "ChevronUpIcon" : "ChevronDownIcon";
    },
  },
  watch: {
    facility_date(val) {
      if (!this.isDisabled) {
        const date = `${val.split("/")[1]}/${val.split("/")[0]}/${
          val.split("/")[2]
        }`;

        this.next_review = moment(String(new Date(date)))
          .add(this.frecuencia_revisiones, "M")
          .format("DD/MM/YYYY");
      }
    },
    frecuencia_revisiones(val) {
      const purchase = this.purchase_date;
      const date = `${purchase.split("/")[1]}/${purchase.split("/")[0]}/${
        purchase.split("/")[2]
      }`;

      this.next_review = moment(String(new Date(date)))
        .add(val, "M")
        .format("DD/MM/YYYY");
    },
    product() {
      if (this.product) {
        if (this.product.type_review) {
          this.isDisabled = false;
          if (
            this.product.type_review.obligatoria === 1 &&
            this.product.type_review.periodica === 0
          ) {
            this.prox_revision_required = "required";
          } else if (
            this.product.type_review.obligatoria === 1 &&
            this.product.type_review.periodica === 1
          ) {
            if (this.purchase_date) {
              const currentDay = new Date();
              this.next_review = currentDay.setMonth(
                currentDay.getMonth() + this.product.type_review.tiempoMeses
              );
              console.log(this.product.type_review.tiempoMeses);
              this.frecuencia_revisiones = this.product.type_review.tiempoMeses;
            }
          } else if (this.product.type_review.obligatoria === 0) {
            this.isDisabled = true;
            this.next_review = null;
          }
        }
      }
    },
  },
  methods: {
    ...mapActions({
      create: "installations/create",
      getSelectDepartments: "departments/selectDepartments",
      getSubClient: "subclients/getSubClient",
    }),
    addSubClient(value) {
      if (this.selectedSubClient !== value) {
        this.selectedCLient = []; //vaciar el array de contactos
      }
      this.selectedSubClient = value;
    },
    addContact(value) {
      value.checked = value.app_access === 1; //trucazo para el checkbox
      this.selectedCLient.push(value);
    },
    changeProduct(value) {
      this.product = value;
    },
    handleSubmit() {
      
      this.$refs.createInstallation.validate()
      .then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ installation: formData });
          }
        } else{
          Object.keys(this.$refs.createInstallation.refs).forEach((key) => {
            const id = this.$refs.createInstallation.refs[key].$parent.$el.id

            if(this.$refs.createInstallation.refs[key].errors.length > 0) {
              const a = document.getElementById(id);
              a.scrollIntoView({
                  behavior: "smooth",
                  block: "end"
              })
              this.$refs.createInstallation.refs[key].$parent.show = true
            }
          })
        }
      })
    },
    handleContactDelete(payload) {
      const { index, item } = payload;
      this.selectedCLient.splice(index, 1);
    },
    addMonths(numOfMonths, date = new Date()) {
      date.setMonth(date.getMonth() + numOfMonths);

      return date;
    },
    isValidDate(date) {
      return (
        date &&
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
      );
    },
    addMarker(event) {
      this.latitude = event.latlng.lat
      this.longitude = event.latlng.lng
      this.marker = event.latlng
    },
    createFormData() {
      let data = this.$refs.documents.getFormData("documents");
      this.$refs.info_docs.getFormData("info_docs", data);
      // const data = new FormData()
      if (this.last_review) {
        data.append("last_review_date", FlatPickrToTimestamp(this.last_review));
      }
      if (this.next_review) {
        data.append("next_review_date", FlatPickrToTimestamp(this.next_review));
      } else {
        data.append("next_review_date", null);
      }
      if (this.facility_date) {
        data.append("purchase_date", FlatPickrToTimestamp(this.facility_date));
      } else {
        data.append("purchase", null);
      }
      if (this.selectedSubClient) {
        data.append("subclient_id", this.selectedSubClient.id);
      }

      if (this.selectedCLient.length > 0) {
        this.selectedCLient.forEach((element) => {
          if (element && element !== null) {
            data.append("users_contact[]", element.id);
          }
        });
      } else {
        data.append("users_contact", "");
      }
      if (this.assets.length > 0) {
        this.assets.forEach((element) => {
          data.append("assets[]", JSON.stringify(element.id));
        });
      }

      data.append("name", this.name);
      data.append("power", this.power);

      data.append("cover_type", this.cover_type);
      data.append("observations", this.observations);
      data.append("security_elements", this.security_elements);

      data.append("product_id", this.product.id);
      data.append("quantity", 1);
      data.append("pedido_num_id", this.pedido_num_id);
      data.append("address", this.address);
      data.append("coste", this.coste);
      data.append("angle", this.angle);
      data.append("linea_vida", this.linea_vida ? 1 : 0);
      data.append("barandillas_seguridad", this.barandillas_seguridad ? 1 : 0);
      data.append("redes_contencion", this.redes_contencion ? 1 : 0);
      data.append("zona_delimitada", this.zona_delimitada ? 1 : 0);

      data.append("client_id", this.currentClient.id);
      data.append('latitude', this.latitude)
      data.append('longitude', this.longitude)
      data.append("production_access", this.production_access);
      
      return data;
    },
  },
  async created() {
    if (this.subclient_id) {
      await this.getSubClient(this.subclient_id);
      this.selectedSubClient = this.subclient;
    }
    const currentDay = new Date();
    (this.next_review = moment(
      currentDay.setMonth(currentDay.getMonth() + this.frecuencia_revisiones)
    ).format("DD/MM/YYYY")),
      await this.getSelectDepartments({ clients: [this.currentClient] });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-control:disabled {
  background-color: #f7f5f0 !important;
}

.collapsed > .card-body > div > .when-opened,
:not(.collapsed) > .card-body > div > .when-closed {
  display: none;
}

.card-body {
  padding: 20px;
}

.card .collapsed {
  border: 1px solid $primary !important;
  color: $primary !important;
}

.card .not-collapsed {
  background-color: $primary !important;
  color: $white !important;
}
.vue2leaflet-map {
  &.leaflet-container {
    height: 450px;
  }
}
</style>
