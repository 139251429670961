<template>
  <validation-observer ref="create-contact">
    <form class="p-2" @submit.prevent>
      <b-row>
        <b-col sm="6">
          <validation-provider #default="{ errors }" :name="$t('Nombre')" rules="required">
            <b-form-group :label="$t('Nombre')" label-for="account-name">
              <b-form-input v-model="model.name" name="name" :placeholder="$t('Nombre')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider #default="{ errors }" :name="$t('Apellidos')" rules="required">
            <b-form-group :label="$t('Apellidos')" label-for="account-surname">
              <b-form-input v-model="model.surname" name="surname" :placeholder="$t('Apellidos')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <validation-provider #default="{ errors }" :name="$t('Telefono')" rules="max:9|min:9">
            <b-form-group :label="$t('Telefono')" label-for="account-phone">
              <b-input-group>
                <template #prepend>
                  <vue-country-code @onSelect="onSelect" :preferredCountries="['es']">
                  </vue-country-code>
                </template>

                <b-form-input type="number" v-model="model.phone" name="company" :placeholder="$t('Telefono')" />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider #default="{ errors }" :name="$t('Email')" rules="required|email">
            <b-form-group :label="$t('Email')" label-for="account-email">
              <b-form-input v-model="model.email" name="email" :placeholder="$t('Email')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import VueCountryCode from "vue-country-code-select";

export default {
  name: "CreateContactForm",
  components: { VueCountryCode },
  mixins: [FormMixin],

  data() {
    return {
      codePhone: "es",
      codePhoneSave: JSON.stringify({
        iso: "ES",
        code: 34,
      }),
    };
  },
  methods: {
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = dialCode;
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      });
      this.model.codePhoneSave = this.codePhoneSave;
    },
    validateForm() {
      return this.$refs["create-contact"].validate();
    },
    resetForm() {
      this.$refs["create-contact"].reset();
      this.$emit("input", {}); // Emitir false para ocultar el modal

    },
  },
};
</script>
