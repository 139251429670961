<template>
  <BaseModal v-model="model" title="Nuevo cliente" size="xl" :loading="loading"
    @confirm-button-click="handleSaveButtonClick" :confirmButtonDisabled="formSent">
    <b-card-title class="m-0 pt-1 px-2">
      <b-row align-v="center">
        <b-col class="font-weight-bold d-flex align-items-center" cols="auto">
          {{ $t("CrearSubClient") }}
        </b-col>
      </b-row>
    </b-card-title>
    <SubClientCreateForm ref="subclient-profile-form" v-model="profile_subclient" />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import SubClientCreateForm from "@/components/subClients/forms/SubClientCreateForm.vue";

export default {
  name: "SubClientCreationModal",
  components: { BaseModal, SubClientCreateForm },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      formSent: false,
      client: {},
      profile_subclient: {},
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    profileForm() {
      return this.$refs["subclient-profile-form"];
    },
  },
  methods: {
    ...mapActions({
      create: "subclients/create",
    }),

    createFormData(clientData) {
      const data = new FormData();
      if (this.profileForm.$refs.images) {
        this.profileForm.$refs.images.getFormData("image", data);
      }

      data.append("name", clientData.name);
      if (clientData.legal_name) data.append("legal_name", clientData.legal_name);
      if (clientData.cif) data.append("cif", clientData.cif);
      if (clientData.email) data.append("email", clientData.email);
      if (clientData.phone) data.append("phone", clientData.phone);
      if (clientData.code_phone) data.append("code_phone", clientData.code_phone);
      if (clientData.web) data.append("web", clientData.web);

      if (clientData.province) {
        data.append("province_id", clientData.province.id);
      }
      if (clientData.town) {
        data.append("town_id", clientData.town.id);
      }

      if (clientData.address) data.append("address", clientData.address);
      if (clientData.postalCode) data.append("postalCode", clientData.postalCode);

      data.append("companies[]", this.currentCompany.id);
      data.append("client_id", this.currentClient.id);
      return data;
    },

    async handleSaveButtonClick() {
      this.$nextTick(async () => {
        const profileValid = await this.$refs["subclient-profile-form"].validateForm();
        // Establecer formSent como true

        if (!profileValid) {
          return;
        }

        const clientData = { ...this.profile_subclient };
        const formData = this.createFormData(clientData);

        this.formSent = true;
        this.loading = true;

        try {
          const response = await this.create({ user: formData, redirect: false });

          if (response && response.data) {
            const newClient = response.data.data;
            this.$emit("subclient-created-successfully", newClient);
            this.$refs["subclient-profile-form"].resetForm(); // Resetear el formulario y ocultar el modal

          } else {
            console.error(
              "La respuesta de la llamada a la API es nula o no tiene datos."
            );
          }

          // Procesar la respuesta y habilitar el botón de confirmación
          this.loading = false;
          this.formSent = false;

        } catch (error) {
          console.error("error", error);
        }
      });


    },


  },
};
</script>

<style scoped></style>
