<template>
  <div>
    <BaseModal 
      v-model="model" 
      :title="$t('NuevoContacto')" 
      size="xl" 
      :loading="loading"
      @confirm-button-click="handleSaveButtonClick"
      :confirmButtonDisabled="formSent"
    >
      <b-card-title class="m-0 pt-1 px-2">
        <b-row align-v="center">
          <b-col class="font-weight-bold d-flex align-items-center" cols="12">
            {{ $t("CrearContactPerson") }}
          </b-col>
        </b-row>
      </b-card-title>
      <CreateContactForm ref="contact-person-form" v-model="contact" />
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import CreateContactForm from "@/components/contactPerson/form/CreateContactForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ContactPersonCreationModal",
  components: {
    BaseModal,
    CreateContactForm,
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
    subclient: {
      type: Number || String,
    },
  },
  data() {
    return {
      loading: false,
      formSent: false,
      contact: {},
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    contactForm() {
      return this.$refs["contact-person-form"];
    },
  },
  methods: {
    ...mapActions({
      create: "users/contact_person",
    }),
    createContactForm(contactData) {

      const data = new FormData();

      data.append("name", contactData.name);
      data.append("surname", contactData.surname);

      data.append("email", contactData.email);
      data.append("phone", contactData.phone);
      data.append("code_phone", contactData.codePhoneSave);

      data.append("companies[]", this.currentCompany.id);
      data.append("clients[]", this.currentClient.id);

      data.append("subclients[]", this.subclient);

      return data;
    },
    async handleSaveButtonClick() {
      const formIsValid = await this.$refs["contact-person-form"].validateForm();


      if (!formIsValid) {
        return false;
      }

      const contactData = { ...this.contact };
      const formData = this.createContactForm(contactData);
      this.loading = true;
      this.formSent = true;

      try {
        //creamdp user
        this.create({ user: formData, redirect: false })
          .then((response) => {
            // Handle success
            const newContact = response.data.data;
            this.$emit("contact-created", newContact);
            this.$refs["contact-person-form"].resetForm();
          })
          .catch(() => {
            // Handle error
            console.error("error al crear", error);
          });

        // Procesar la respuesta y habilitar el botón de confirmación
        this.formSent = false;
        this.loading = false;
      } catch (error) {
        console.error("error", error);
      }

    },
  },
};
</script>

<style scoped>
/* Aquí van los estilos específicos de la modal */
</style>
