<template>
  <b-modal v-model="model" :title="title" :size="size" centered :scrollable="scrollable" :hide-footer="!showFooter">
    <b-overlay :show="loading" variant="white" spinner-variant="primary" rounded="sm">
      <slot />
    </b-overlay>
    <template #modal-footer>
      <b-row align-h="end">
        <b-col cols="auto">
          <b-button variant="outline-primary" class="mr-1 text-nowrap rounded-sm" @click="$emit('input', false)">
            {{ cancelButtonText || $t('Cancelar') }}
          </b-button>
          <b-button variant="primary" class="text-nowrap rounded-sm" :disabled="confirmButtonDisabled"
            @click="$emit('confirm-button-click')">
            {{ confirmButtonText || $t('Guardar') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "xl",
    },
    title: {
      type: String,
      default: "",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
