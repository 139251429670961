<template>
  <b-row
    align-v="center"
    no-gutters
  >
    <b-col>
      <BaseSelect
        :id="id"
        :value="value"
        :resource="'/assets'"
        :multiple="true"
        class="base-action-select"
        :disabled="disabled"
        :httpBody="{ id_client: currentClient.id }"
        @input="$emit('input', $event)"
        @selected="$emit('selected', $event)"
      />
    </b-col>
    <b-col
      v-show="!disabled"
      cols="auto"
    >
      <b-button
        variant="primary"
        class="text-nowrap rounded-right d-inline-block base-select-add-button"
        @click="creationModalVisible = true"
      >
        <feather-icon
          icon="PlusIcon"
          size="19"
        />
      </b-button>
    </b-col>
    <ModalCreateAsset
      v-model="creationModalVisible"
      @assetsCreated="[$emit('input', [...value, ...$event]), creationModalVisible = false]"
    />
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import ModalCreateAsset from '@/components/assets/modal/ModalCreateAsset.vue'

export default {
  name: 'ClientSelect',
  components: { 
    ModalCreateAsset, 
    BaseSelect 
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
    }),
  }
}
</script>