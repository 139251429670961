var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"create-subclient-profile"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"auth-create-form",attrs:{"lg":"4"}},[_c('ImageDropzone',{ref:"images",attrs:{"files":_vm.files},on:{"image-selected":_vm.handleImageSelected}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"account-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('LegalName'),"label-for":"account-legal_name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LegalName'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('LegalName'),"name":"legal_name"},model:{value:(_vm.model.legal_name),callback:function ($$v) {_vm.$set(_vm.model, "legal_name", $$v)},expression:"model.legal_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":_vm.$t('CIF')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CIF'),"label-for":"account-cif"}},[_c('b-form-input',{attrs:{"name":"cif","placeholder":_vm.$t('CIF')},model:{value:(_vm.model.cif),callback:function ($$v) {_vm.$set(_vm.model, "cif", $$v)},expression:"model.cif"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Telefono'),"rules":"max:9|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"account-phone"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{attrs:{"preferredCountries":['es']},on:{"onSelect":_vm.onSelect}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","name":"company","placeholder":_vm.$t('Telefono')},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"email|emailUnique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"type":"email","name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('Web')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Web'),"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Web')},model:{value:(_vm.model.web),callback:function ($$v) {_vm.$set(_vm.model, "web", $$v)},expression:"model.web"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"offset-lg-4",attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Province'),"label-for":"province"}},[_c('BaseSelect',{attrs:{"resource":'/provinces',"value":_vm.province,"label":'Provincia',"clearable":!_vm.town},on:{"input":function($event){[(_vm.province = $event), (_vm.town = ''), (_vm.townsKey = new Date())]}},model:{value:(_vm.model.province),callback:function ($$v) {_vm.$set(_vm.model, "province", $$v)},expression:"model.province"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('Direccion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Direccion')},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Towns'),"label-for":"town"}},[_c('BaseSelect',{key:("" + _vm.townsKey),attrs:{"resource":'/towns',"value":_vm.town,"httpBody":{ province_id: _vm.province.id },"label":'Municipio',"readonly":!_vm.province,"clearable":true},on:{"input":function($event){_vm.town = $event}},model:{value:(_vm.model.town),callback:function ($$v) {_vm.$set(_vm.model, "town", $$v)},expression:"model.town"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('CodigoPostal')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CodigoPostal'),"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CodigoPostal')},model:{value:(_vm.model.postalCode),callback:function ($$v) {_vm.$set(_vm.model, "postalCode", $$v)},expression:"model.postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }