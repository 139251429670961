<template>
  <BaseModal
    v-model="model"
    :title='$t("Añadiractivos")'
    size="xl"
    :loading="loading"
    @confirm-button-click="handleSaveButtonClick"
  >
   <Create 
      ref="createAsset" 
      :viewType="'COMPONENT'" 
      :key="keyForm" 
      @assetsCreated="$emit('assetsCreated', $event)"
      @loading="loading = $event" 
    />
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import Create from "@/views/assets/Create.vue"

export default {
  components: {
    BaseModal,
    Create
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      keyForm: `${new Date()}`
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.keyForm = `${new Date()}`
        }
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async handleSaveButtonClick() {
      this.$refs["createAsset"].handleSubmit()
    },
  }
}

</script>
