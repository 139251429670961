<template>
  <b-card :class="{'no-card-style': viewType !== 'VIEW'}">
    <!-- form -->
    <validation-observer ref="createAsset">
      <b-form
        :class="{'mt-2': viewType !== 'VIEW'}"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col v-if="viewType === 'VIEW'" cols="12">
            <h4 class="mb-2">
              {{ $t("Añadiractivos") }}
            </h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('NumeroSerie')"
            >
              <b-form-group
                :label="$t('NumeroSerie')"
                label-for="asset-serial_number"
              >
                <b-form-input
                  v-model="serial_number"
                  name="serial_number"
                  :placeholder="$t('NumeroSerie')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
                :label="$t('NumeroAlbaran')"
                label-for="asset-pedido_num_id"
            >
              <b-form-input
                  v-model="pedido_num_id"
                  name="pedido_num_id"
                  :placeholder="$t('NumeroAlbaran')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="currentCompany.id === 3">
            <validation-provider
                #default="{ errors }"
                :name="$t('Coste')"
                rules=""
            >
              <b-form-group
                  :label="$t('Coste')"
                  label-for="equipos-coste"
              >
                <b-input-group append="€">
                  <b-form-input
                      v-model="coste"
                      name="equipos-coste"
                      type="number"
                      :placeholder="$t('Coste')"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Localizacion')"
              label-for="asset-location"
            >
              <v-select
                v-model="location"
                label="name"
                :filterable="false"
                :searchable="false"
                :options="selectLocations"
                :placeholder="$t('Localizacion')"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Producto')"
              rules="required"
            >
              <b-form-group
                :label="$t('Producto')"
                label-for="asset-product"
              >
                <SelectProducts v-model="product" type="asset" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('FechaCompra')"
              rules="required"
            >
              <b-form-group
                :label="$t('FechaCompra')"
                label-for="asset-purchase_date"
              >
                <flat-pickr
                  v-model="purchase_date"
                  name="purchase_date"
                  class="form-control"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('ProximaRevision')"
              :rules="prox_revision_required"
            >
              <b-form-group
                :label="$t('ProximaRevision')"
                label-for="asset-next_review"
              >
                <flat-pickr
                  v-model="next_review"
                  name="next_review"
                  class="form-control"
                  :disabled="isDisabled"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('NumeroDeEquipos')"
              rules="required"
            >
              <b-form-group
                :label="$t('NumeroDeEquipos')"
                label-for="num-equipos-revisiones"
              >
                <b-form-input
                  v-model="num_equipos"
                  name="numero-equipos"
                  type="number"
                  :placeholder="$t('NumeroDeEquipos')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="$t('Descripcion')"
              label-for="asset-description"
            >
              <quill-editor v-model="description" />
            </b-form-group>
          </b-col>
          <b-col v-if="viewType === 'VIEW'" cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t("Enviar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp } from '@/libs/helpers'
import vSelect from 'vue-select'
import moment from 'moment'
import SelectProducts from '@/components/selectProducts/SelectProducts.vue'

export default {
  props: {
    viewType: {
      type: String,
      default: 'VIEW'
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    SelectProducts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      name: '',
      serial_number: '',
      location: '',
      pedido_num_id: '',
      description: '',
      last_review: '',
      purchase_date: moment(new Date()).format('DD/MM/YYYY'),
      frecuencia_revisiones: 12,
      next_review: '',
      product: '',
      user: '',
      files: [],
      review: [],
      delivery: [],
      status: '',
      num_equipos: 1,
      required,
      email,
      prox_revision_required: '',
      isDisabled: false,
      search: '',
      coste: '',
    }
  },
  watch: {
    purchase_date(val) {
      if (!this.isDisabled) {
        const date = `${val.split('/')[1]}/${val.split('/')[0]}/${val.split('/')[2]}`

        this.next_review = moment(String(new Date(date)))
          .add(this.frecuencia_revisiones, 'M')
          .format('DD/MM/YYYY')
      }
    },
    frecuencia_revisiones(val) {
      const purchase = this.purchase_date
      const date = `${purchase.split('/')[1]}/${purchase.split('/')[0]}/${purchase.split('/')[2]}`

      this.next_review = moment(String(new Date(date)))
        .add(val, 'M')
        .format('DD/MM/YYYY')
    },
    product() {
      if (this.product) {
        if (this.product.type_review) {
          this.isDisabled = false
          if (this.product.type_review.obligatoria === 1 && this.product.type_review.periodica === 0) {
            this.prox_revision_required = 'required'
          } else if (this.product.type_review.obligatoria === 1 && this.product.type_review.periodica === 1) {
            if (this.purchase_date) {
              const ahora = new Date()
              this.next_review = ahora.setMonth(
                ahora.getMonth() + this.product.type_review.tiempoMeses,
              )
              console.log(this.product.type_review.tiempoMeses)
              this.frecuencia_revisiones = this.product.type_review.tiempoMeses
            }
          } else if (this.product.type_review.obligatoria === 0) {
            this.isDisabled = true
            this.next_review = null
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      totalItems: 'products/getTotalItems',
      localeDatePicker: 'languages/getLocaleDatePicker',
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      selectDepartments: 'departments/getSelectDepartments',
      selectLocations: 'locations/getSelectLocations',
    }),
  },
  methods: {
    ...mapActions({
      create: 'assets/create',
      getSelectDepartments: 'departments/selectDepartments',
      getSelectLocations: 'locations/getSelectLocations',
    }),
    changeProduct(value) {
      this.product = value
    },
    async handleSubmit() {
      this.$refs.createAsset.validate().then(async (success) => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.$emit('loading', true)
            const response = await this.create({ asset: formData, reload: this.viewType === 'VIEW' })
            this.$emit('assetsCreated', response.data)
            this.$emit('loading', false)
          }
        }
      })
    },
    addMonths(numOfMonths, date = new Date()) {
      date.setMonth(date.getMonth() + numOfMonths)

      return date
    },
    isValidDate(date) {
      return (
        date
        && Object.prototype.toString.call(date) === '[object Date]'
        && !isNaN(date)
      )
    },
    createFormData() {
      /*   let data = this.$refs.documents.getFormData("documents"); */
      const data = new FormData()
      if (this.last_review) {
        data.append('last_review_date', FlatPickrToTimestamp(this.last_review))
      }

      if (this.next_review) {
        data.append('next_review_date', FlatPickrToTimestamp(this.next_review))
      } else {
        data.append('next_review_date', null)
      }
      if (this.purchase_date) {
        data.append('purchase_date',FlatPickrToTimestamp(this.purchase_date))
      } else {
        data.append('purchase_date', null)
      }

      data.append('status_asset_id', 1)
      if (this.location) {
        data.append('origin_location_id', this.location.id)
        data.append('location_id', this.location.id)
      }
      data.append('client_id', this.currentClient.id)
      data.append('serial_number', this.serial_number)
      data.append('description', this.description)
      data.append('product_id', this.product.id)
      data.append('quantity', this.num_equipos)
      data.append('pedido_num_id', this.pedido_num_id)
      data.append('coste', this.coste)

      return data
    },
  },
  async created() {
    const ahora = new Date()
    this.next_review = moment(ahora.setMonth(
      ahora.getMonth() + this.frecuencia_revisiones,
    )).format('DD/MM/YYYY'),
    // await this.getSelectStatus()
    await this.getSelectDepartments({ clients: [this.currentClient] })
    await this.getSelectLocations({ clients: [this.currentClient.id] })
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-control:disabled{
  background-color: #f8f8f8 !important;
}
.no-card-style {
  box-shadow: none;
}
</style>
