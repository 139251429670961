<template>
  <b-table class="" hover striped responsive :items="items" :fields="fields">
    <template v-slot:cell(status)="data">
      <div class="text-center">
        <b-form-checkbox v-model="data.item.checked" switch inline :disabled="data.item.app_access === 1"
          @click.native.prevent="changeAccess(data.item)"></b-form-checkbox>
      </div>
    </template>
    <template v-slot:cell(actions)="data">
      <div class="text-right">
        <span class="text-danger cursor-pointer" @click="sendDelete(data.index)">
          <feather-icon icon="TrashIcon" size="20" />
        </span>
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditableContactPersonList",
  props: {
    items: {
      type: Array,
    },
    index: {
      type: Number,
    },
  },

  computed: {

    fields() {
      return [
        { key: "name", label: this.$t("Nombre") },
        { key: "surname", label: this.$t("Apellidos") },
        { key: "phone", label: this.$t("Telefono") },
        { key: "email", label: this.$t("Email") },
        { key: "status", label: this.$t("AccesoApp") },
        { key: "actions", label: this.$t("Acciones") },
      ];
    },
  },
  methods: {
    ...mapActions({
      app_access: "users/app_access",
    }),
    sendDelete(index) {
      // Handle the client contact person deletion
      console.log('vamos a borrar', index);
      this.$emit("delete", { index, item: this.items[index] });
    },
    changeAccess(item) {
      if (item.app_access !== 1) {
        this.$bvModal
          .msgBoxConfirm(this.$t("DarAccesoApp", { nombre: item.name }), {
            bodyClass: "bodyEdit",
            footerClass: "footerDelete",
            okVariant: "primary",
            okTitle: this.$t("Confirmar"),
            cancelTitle: this.$t("Cancelar"),
            cancelVariant: "outline-primary",
            centered: true,
          })
          .then((value) => {
            if (value === true) {
              this.app_access(item.id);
              this.$set(item, 'checked', true);
              this.$emit("reloadContact", { item: item });

            } else {
              //change the status of the switch
              console.log('estamos cambiando?')
              this.$set(item, 'checked', false);
            }
          });
      }
    },
    initializeCheckedState() {
      this.items.forEach(item => {
        this.$set(item, 'checked', item.app_access === 1);
      });
    },
  },
  created() {
    this.initializeCheckedState();
  },
};
</script>
