<template>
  <validation-observer ref="create-subclient-profile">
    <b-form class="p-2" @submit.prevent>
      <b-row>
        <!-- image -->
        <b-col lg="4" class="auth-create-form">
          <ImageDropzone ref="images" :files="files" @image-selected="handleImageSelected" />
        </b-col>
        <!-- name data -->
        <b-col lg="4">
          <validation-provider #default="{ errors }" :name="$t('Nombre')" rules="required">
            <b-form-group :label="$t('Nombre')" label-for="account-name">
              <b-form-input v-model="model.name" name="name" :placeholder="$t('Nombre')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-group :label="$t('LegalName')" label-for="account-legal_name">
            <validation-provider #default="{ errors }" :name="$t('LegalName')" rules="">
              <b-form-input v-model="model.legal_name" :placeholder="$t('LegalName')" name="legal_name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider #default="{ errors }" :name="$t('CIF')">
            <b-form-group :label="$t('CIF')" label-for="account-cif">
              <b-form-input v-model="model.cif" name="cif" :placeholder="$t('CIF')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- contact data -->
        <b-col lg="4">
          <validation-provider #default="{ errors }" :name="$t('Telefono')" rules="max:9|min:9">
            <b-form-group :label="$t('Telefono')" label-for="account-phone">
              <b-input-group>
                <template #prepend>
                  <vue-country-code @onSelect="onSelect" :preferredCountries="['es']">
                  </vue-country-code>
                </template>

                <b-form-input type="number" v-model="model.phone" name="company" :placeholder="$t('Telefono')" />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" :name="$t('Email')" rules="email|emailUnique">
            <b-form-group :label="$t('Email')" label-for="account-e-mail">
              <b-form-input v-model="model.email" type="email" name="email" :placeholder="$t('Email')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" :name="$t('Web')">
            <b-form-group :label="$t('Web')" label-for="client-web">
              <b-form-input v-model="model.web" name="reference" :placeholder="$t('Web')" />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col lg="4" class="offset-lg-4">
          <b-form-group :label="$t('Province')" label-for="province">
            <BaseSelect :resource="'/provinces'" @input="
            [(province = $event), (town = ''), (townsKey = new Date())]
            " v-model="model.province" :value="province" :label="'Provincia'" :clearable="!town" />
          </b-form-group>

          <validation-provider #default="{ errors }" :name="$t('Direccion')">
            <b-form-group :label="$t('Direccion')" label-for="client-address">
              <b-form-input v-model="model.address" name="reference" :placeholder="$t('Direccion')" />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="$t('Towns')" label-for="town">
            <BaseSelect :resource="'/towns'" @input="town = $event" :value="town" v-model="model.town"
              :httpBody="{ province_id: province.id }" :label="'Municipio'" :readonly="!province" :key="`${townsKey}`"
              :clearable="true" />
          </b-form-group>

          <validation-provider #default="{ errors }" :name="$t('CodigoPostal')">
            <b-form-group :label="$t('CodigoPostal')" label-for="client-postalCode">
              <b-form-input v-model="model.postalCode" name="reference" :placeholder="$t('CodigoPostal')" />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import VueCountryCode from "vue-country-code-select";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";

export default {
  name: "SubClientCreateForm",
  components: { ImageDropzone, VueCountryCode, BaseSelect },
  mixins: [FormMixin],
  props: {},
  data() {
    return {
      townsKey: new Date(),
      AngleIcon: require("@/assets/images/icons/angle.svg"),

      codePhone: "es",
      codePhoneSave: JSON.stringify({
        iso: "ES",
        code: 34,
      }),

      files: [],
      avatar: "",
      province: "",
      town: "",
    };
  },
  computed: {
    clientImage() {
      if (this.model.image) {
        return this.model.image.path || this.model.image;
      }

      return null;
    },
  },
  methods: {
    resetForm() {
      this.$refs["create-subclient-profile"].reset();
      this.$emit("input", {}); // Emitir false para ocultar el modal
      this.profile_subclient = {
        name: "",
        legal_name: "",
        cif: "",
        phone: "",
        email: "",
        web: "",
        province: "",
        address: "",
        town: "",
        postalCode: "",
      };
      this.files = [];
      this.avatar = "";
      this.province = "";
      this.town = "";
    },
    validateForm() {
      return this.$refs["create-subclient-profile"].validate();
    },
    isImageRemoved() {
      return this.imageRemoved;
    },
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = dialCode;
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      });
    },
    handleImageSelected(image) {
      this.model.image = image;
    },
  },
};
</script>

<style scoped></style>
